<div *ngIf="lineChartData">
  <canvas
    role="graphics-datachart"
    baseChart
    [data]="lineChartData"
    [options]="lineChartOptions"
    [type]="lineChartType"
    [attr.aria-label]="chartAriaLabel"
    aria-describedby="#presentation-description"
    longdesc="#figure-caption"></canvas>
</div>
