import { Component, Input } from '@angular/core'
import { ChartStructure, LineChartDataModel } from '@cts/cedar-public-api'
import {
  Chart,
  ChartConfiguration,
  ChartEvent,
  ChartType,
  ChartTypeRegistry,
  LegendElement,
  LegendItem
} from 'chart.js'

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent {
  public hasData = false
  public rowIndices: Array<number> = []
  public chartAriaLabel?: string
  private _disableAnimation: boolean = true

  public lineChartData: ChartConfiguration['data'] | null = null
  public lineChartOptions: ChartConfiguration['options']

  public lineChartType: ChartType = 'line'

  @Input() set disableAnimation(value: boolean) {
    this._disableAnimation = value
    this.updateAnimationOption(this.lineChartOptions, value)
  }

  private updateAnimationOption(
    lineChartOptions: ChartConfiguration['options'],
    animationDisabled: boolean
  ) {
    if (!lineChartOptions) return
    lineChartOptions.animation = animationDisabled ? { duration: 0 } : undefined
  }

  @Input() set srTitle(value: string | undefined) {
    this.chartAriaLabel = value ? 'Chart showing ' + value : ''
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  @Input() set data(value: LineChartDataModel | null) {
    if (value) {
      this.hasData = true
      this.lineChartData = {
        datasets: [
          {
            label: value.series[0].label,
            borderDash: [7, 3],
            yAxisID: value.structure === ChartStructure.TwoLinesTwoYAxes ? 'y-axis-0' : undefined,
            pointBackgroundColor: 'rgba(255,99,132,1)',
            pointBorderColor: 'rgba(255,99,132,1)',
            pointStyle: 'rectRot',
            borderColor: 'rgba(255,99,132,1)',
            data: value.series[0].data,
            borderWidth: 1.5
          },
          {
            data: value.series[1].data,
            label: value.series[1].label,
            yAxisID: value.structure === ChartStructure.TwoLinesTwoYAxes ? 'y-axis-1' : undefined,
            backgroundColor: 'rgba(95,107,140,1)',
            pointBackgroundColor: 'rgba(95,107,140,1)',
            pointBorderColor: 'rgba(95,107,140,1)',
            borderColor: 'rgba(95,107,140,1)',
            borderWidth: 1
          }
        ],

        labels:
          value.structure === ChartStructure.YearOnYear
            ? value?.xAxisLabels.map((value) =>
                new Date(value).toLocaleDateString('en', { month: 'long' })
              )
            : value?.xAxisLabels.map((value) =>
                new Date(value).toLocaleDateString('en', { year: 'numeric', month: 'short' })
              )
      }

      this.rowIndices = Array.from(Array(this.lineChartData.labels?.length).keys())

      this.lineChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        elements: {
          line: {
            tension: 0.5
          }
        },
        scales: {
          x: {}
        },
        plugins: {
          legend: { position: 'bottom' }
        }
      }

      if (value.structure === ChartStructure.TwoLinesTwoYAxes) {
        Object.assign(this.lineChartOptions.scales!, {
          'y-axis-0': {
            display: 'auto',
            position: 'left',
            ticks: {
              color: 'rgba(255,99,132,1)',
              callback: this.scaleAdjust(value.series[0].data)
            }
          },
          'y-axis-1': {
            display: 'auto',
            position: 'right',
            grid: { display: false },
            ticks: {
              color: '#00154b',
              callback: this.scaleAdjust(value.series[1].data)
            }
          }
        })

        Object.assign(this.lineChartOptions.plugins?.legend!, {
          onClick: this.legendClick
        })
      } else {
        Object.assign(this.lineChartOptions.scales!, {
          y: {
            position: 'left',
            ticks: {
              callback: this.scaleAdjust(value.series[0].data.concat(value.series[1].data))
            }
          }
        })
      }
      if (this.disableAnimation) {
        this.lineChartOptions.animation = { duration: 0 }
      }

      this.updateAnimationOption(this.lineChartOptions, this._disableAnimation)
    } else {
      this.lineChartData = null
    }
  }

  /* eslint-enable @typescript-eslint/naming-convention */

  public scaleAdjust(values: (number | null)[]): (number: number) => string {
    var magnitude = LineChartComponent.getMagnitude(values)

    switch (magnitude) {
      case 6:
        return (value) => (value ? (value / 1000000).toLocaleString() + ' M' : '')
      case 3:
        return (value) => (value ? (value / 1000).toLocaleString() + ' K' : '')
      default:
        return (value) => (value ? value.toLocaleString() : '')
    }
  }

  private static getMagnitude(values: (number | null)[]) {
    var valuesToCheck = values.map((v) => v || 0)
    return valuesToCheck.length
      ? Math.min(Math.floor(Math.log10(Math.min(...valuesToCheck)) / 3) * 3, 6)
      : 0
  }

  private static getAxisScaleDescriptor(values: (number | null)[], units?: string) {
    var magnitude = LineChartComponent.getMagnitude(values)
    switch (magnitude) {
      case 3:
        return `The axis scale is in thousands of ${units ?? 'units'} (K).`

      case 6:
        return `The axis scale is in millions of ${units ?? 'units'} (M).`
      default:
        return ''
    }
  }

  public legendClick(
    this: LegendElement<keyof ChartTypeRegistry>,
    e: ChartEvent,
    legendItem: LegendItem,
    legend: LegendElement<keyof ChartTypeRegistry>
  ) {
    // Do the original logic
    Chart.defaults.plugins.legend.onClick.call(this, e, legendItem, legend)

    const index = legendItem.datasetIndex
    if (index == 0) {
      const axisToAffect = this.chart!.options!.scales!['y-axis-1']!
      axisToAffect.grid!.display = legendItem.hidden
    }
  }
}
