import { Component, Input } from '@angular/core'

import { TopCountriesTableModel } from './top-countries-table-model'

@Component({
  selector: 'app-top-countries-table',
  templateUrl: './top-countries-table.component.html',
  styleUrls: ['./top-countries-table.component.scss']
})
export class TopCountriesTableComponent {
  model!: TopCountriesTableModel

  @Input({ required: true }) set inputModel(value: TopCountriesTableModel) {
    this.model = value
  }
}
