import { Component, Input } from '@angular/core'

import { TopPortsTableModel } from './top-ports-table-model'

@Component({
  selector: 'app-top-ports-table',
  templateUrl: './top-ports-table.component.html',
  styleUrls: ['./top-ports-table.component.scss']
})
export class TopPortsTableComponent {
  model!: TopPortsTableModel

  @Input({ required: true }) set inputModel(value: TopPortsTableModel) {
    this.model = value
  }
}
