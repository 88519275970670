<ng-container *ngIf="model && model.rows.length">
  @let rows = model.rows;

  <div class="flex justify-between items-center gap-1 pb-6">
    <div class="flex gap-2 items-center">
      <span
        class="font-bold text-base cursor-pointer"
        [ngClass]="{ 'text-black/40': model.selectedImex !== 'exports' }"
        (click)="model.toggleTopCountriesImexDirection()">
        Exports
      </span>
      <span class="font-bold text-base">|</span>
      <span
        class="font-bold text-base cursor-pointer"
        [ngClass]="{ 'text-black/40': model.selectedImex !== 'imports' }"
        (click)="model.toggleTopCountriesImexDirection()">
        Imports
      </span>
    </div>
  </div>
  <table class="table-auto border-collapse w-full">
    <thead>
      <tr>
        <th scope="col" class="px-4 py-2 bg-black/5 text-start max-w-16">
          <div
            class="flex items-center gap-2 cursor-pointer"
            (click)="model.toggleSort('nameSortOrdinal')"
            title="Rank by volume">
            <span class="text-secondary-700 font-medium text-sm leading-4">Rank</span>
            <img
              class="w-fit text-end rem:h-[14px]"
              src="../assets/fonts/icon-sort.svg"
              alt="icon" />
          </div>
        </th>

        <th scope="col" class="px-4 py-2 bg-black/5 text-start min-w-40">
          <div class="flex items-center gap-2 cursor-pointer" (click)="model.toggleSort('name')">
            <span class="text-secondary-700 font-medium text-sm leading-4">Country </span>
            <img
              class="w-fit text-end rem:h-[14px]"
              src="../assets/fonts/icon-sort.svg"
              alt="icon" />
          </div>
        </th>
        <th scope="col" class="px-4 py-2 bg-black/5">
          <div
            class="flex items-center gap-2 justify-center cursor-pointer"
            (click)="model.toggleSort('data.yoy')">
            <span class="text-secondary-700 font-medium text-sm leading-4">YoY</span>
            <img
              class="w-fit text-end rem:h-[14px]"
              src="../assets/fonts/icon-sort.svg"
              alt="icon" />
          </div>
        </th>
        <th scope="col" class="px-4 py-2 bg-black/5">
          <div
            class="flex items-center gap-2 justify-center cursor-pointer"
            (click)="model.toggleSort('data.mom')">
            <span class="text-secondary-700 font-medium text-sm leading-4">MoM</span>
            <img
              class="w-fit text-end rem:h-[14px]"
              src="../assets/fonts/icon-sort.svg"
              alt="icon" />
          </div>
        </th>
        <th scope="col" class="px-4 py-2 bg-black/5">
          <div
            class="flex items-center gap-2 justify-center cursor-pointer"
            (click)="model.toggleSort('data.ytd')">
            <span class="text-secondary-700 font-medium text-sm leading-4">YTD</span>
            <img
              class="w-fit text-end rem:h-[14px]"
              src="../assets/fonts/icon-sort.svg"
              alt="icon" />
          </div>
        </th>
        <th scope="col" class="px-4 py-2 bg-black/5 text-start"></th>
      </tr>
    </thead>
    <tbody>
      <tr onclick="" class="hover:bg-secondary-250" *ngFor="let dataRow of rows">
        <td class="px-4 py-1 max-w-16">
          <span class="text-secondary-700 text-sm">
            {{ dataRow.nameSortOrdinal }}
          </span>
        </td>
        <td class="px-4 py-1 min-w-24">
          <span class="text-secondary-700 text-sm">
            {{ dataRow.name }}
          </span>
        </td>
        <td class="px-4 py-1 w-24 text-center whitespace-nowrap">
          <span class="text-secondary-700 text-sm">
            {{ dataRow.data.yoy > 0 ? '+' : '' }}{{ dataRow.data.yoy | number: '1.1-1' }}%
          </span>
        </td>
        <td class="px-4 py-1 w-24 text-center">
          <span class="text-secondary-700 text-sm whitespace-nowrap">
            {{ dataRow.data.mom > 0 ? '+' : '' }}{{ dataRow.data.mom | number: '1.1-1' }}%
          </span>
        </td>
        <td class="px-4 py-1 w-24 text-center whitespace-nowrap">
          <span class="text-secondary-700 text-sm">
            {{ dataRow.data.ytd > 0 ? '+' : '' }}{{ dataRow.data.ytd | number: '1.1-1' }}%
          </span>
        </td>
        <td
          class="px-4 py-1 w-10 cursor-pointer"
          title="View current data for {{ dataRow.name }}"
          [routerLink]="['/explore-insights/country', dataRow.countryId]">
          <img
            class="text-end rem:h-[16px] max-w-2"
            src="../assets/fonts/icon-arrow-expand.svg"
            alt="icon" />
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
