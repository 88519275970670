import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { BaseChartDirective } from 'ng2-charts'

import { CountryAutoCompleteComponent } from './components/country-auto-complete/country-auto-complete.component'
import { CountryPortLevelCtaComponent } from './components/country-port-level-cta/country-port-level-cta.component'
import { LineChartComponent } from './components/line-chart/line-chart.component'
import { TopCountriesTableComponent } from './components/top-countries-table/top-countries-table.component'
import { TopPortsTableComponent } from './components/top-ports-table/top-ports-table.component'

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, BaseChartDirective],

  declarations: [
    LineChartComponent,
    CountryPortLevelCtaComponent,
    TopCountriesTableComponent,
    TopPortsTableComponent,
    CountryAutoCompleteComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CountryAutoCompleteComponent,
    CountryPortLevelCtaComponent,
    LineChartComponent,
    TopCountriesTableComponent,
    TopPortsTableComponent
  ]
})
export class SharedModule {}
