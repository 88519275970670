<div class="relative w-full group pl-2">
  <div>
    <input
      #searchInput
      autocomplete="off"
      id="countrySearch"
      class="min-w-44 group-hover:placeholder:text-slate-700 placeholder:text-black focus:bg-white block w-full caret-slate-900 text-sm 3xl:text-base 4xl:text-2xl px-2 font-normal text-slate-500 focus:text-slate-900 border border-slate-200 focus:outline-none focus:border-slate-400"
      type="text"
      [value]="initialValue"
      (input)="onInput($event)"
      (keyup.enter)="enterKeyUp()"
      (blur)="onBlur()"
      (focus)="onFocus()"
      placeholder="Search" />
    <div class="absolute inset-y-3 right-0 mr-3 ml-6">
      <img class="w-fit text-end rem:h-[14px]" src="../assets/fonts/icon-search.svg" alt="icon" />
    </div>
  </div>

  @let countries = candidateCountries$ | async;

  <div
    id="countryDropDown"
    [ngClass]="{ hidden: (searchEnabled$ | async) === false || !countries?.length || !hasFocus }"
    class="z-10 absolute bg-white flex grid divide-y divide-gray-100 shadow min-w-48 mt-[1px]">
    <ul
      class="text-sm text-gray-900 dark:text-gray-200 text-sm 3xl:text-base 4xl:text-2xl"
      aria-labelledby="dropdownDefaultButton">
      <li *ngFor="let country of countries">
        <a
          (ng-mousedown)="onFocus()"
          class="block px-4 py-2 hover:bg-gray-100 whitespace-nowrap"
          title="View current data for {{ country.name }}"
          [routerLink]="['/explore-insights/country', country.id]">
          {{ country.name }}</a
        >
      </li>
    </ul>
  </div>
</div>
