<div
  class="flex flex-col md:flex-row gap-3 items-center bg-secondary-350 p-8 justify-between max-w-[968px] w-full max-w-full">
  <div class="flex flex-col gap-2 text-center md:text-start">
    <span class="text-secondary-800 font-medium text-xl leading-6"
      >Interested in Country or Port level Data?</span
    >
    <span class="text-secondary-800 text-sm leading-6"
      >Contact CTS to discuss your specific requirements and get a quote</span
    >
  </div>
  <div class="w-full md:w-auto">
    <button
      [routerLink]="['/subscriptions/custom-data-request']"
      type="button"
      class="w-full bg-black px-4 py-3 h-14 text-sm font-medium leading-6 text-white duration-200 hover:bg-[#605F62] !min-w-[150px] !h-12">
      <span>Learn more</span>
    </button>
  </div>
</div>
