/**
 * Cedar Public API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TradeVolumeImExItem { 
    fromId: number;
    toId: number;
    fromName: string;
    toName: string;
    mom: number;
    yoy: number;
    ytd: number;
}

