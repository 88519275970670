import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { Country } from '@cts/cedar-public-api'
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  share,
  Subject,
  take,
  tap,
  withLatestFrom
} from 'rxjs'

@Component({
  selector: 'app-country-auto-complete',
  templateUrl: './country-auto-complete.component.html',
  styleUrls: ['./country-auto-complete.component.scss']
})
export class CountryAutoCompleteComponent implements OnInit {
  @Input({ required: true })
  public countrySource!: Observable<Country[]>

  @Input()
  public initialValue: string = ''

  @Input()
  public debounceTimeMs = 200

  @Input()
  public searchMinLengthChars = 3

  //for template
  public candidateCountries$!: Observable<Country[]>
  public searchEnabled$!: Observable<boolean>

  @ViewChild('searchInput')
  public searchInput!: ElementRef

  public hasFocus = false

  clearSearchInput() {
    this.searchInput.nativeElement.value = ''
  }

  //rx logic and component
  private inputValue = new Subject<string>()
  private singleAvailableSelection: Country | null = null
  private countriesLoaded = false

  constructor(private router: Router) {}

  ngOnInit(): void {
    //don't do searches or show the dropdown when the search text is very short, or if the server hasn't replied yet
    var searchEnabled = (this.searchEnabled$ = this.inputValue.pipe(
      map((searchTerm: string) => searchTerm.trim().length >= this.searchMinLengthChars),
      share()
    ))

    var searchTransform = (countryName: string) =>
      countryName
        .trim()
        .toLowerCase()
        .replace(/[\W_]+/g, ' ')

    // don't allow hammering the search whilst the user types each key, then deduplicate
    // also don't emit small strings
    var searchTerms = this.inputValue.pipe(
      debounceTime(this.debounceTimeMs),
      distinctUntilChanged(),
      withLatestFrom(searchEnabled),
      filter(([_, searchEnabled]) => searchEnabled),
      map(([searchTerm, _]) => searchTransform(searchTerm))
    )

    //subscribe to server data immediately after the first keypress
    var serverData = combineLatest([this.inputValue.pipe(take(1)), this.countrySource]).pipe(
      map(([_, countries]) => countries),
      tap((_) => {
        if (this.countriesLoaded) {
          this.clearSearchInput()
          this.inputValue.next('')
        } else {
          this.countriesLoaded = true
        }
      }),
      map((countries) =>
        countries.map((country) => ({
          searchKey: searchTransform(country.name),
          countryItem: country
        }))
      )
    )

    //  wait for the server data to be available
    // and for a search term to be present
    // then take the latest search term and filter the country list accordingly

    this.candidateCountries$ = combineLatest([serverData, searchTerms]).pipe(
      map(([serverData, searchTerm]) => {
        return serverData
          .filter((country) => country.searchKey.includes(searchTerm))
          .map((match) => match.countryItem)
      }),
      tap((matches) => (this.singleAvailableSelection = matches.length === 1 ? matches[0] : null))
    )
  }

  onInput(event: any): void {
    this.inputValue.next(event.target.value)
  }

  onBlur(): void {
    setTimeout(() => (this.hasFocus = false), 500)
  }

  onFocus(): void {
    this.hasFocus = true
  }

  enterKeyUp(): void {
    if (this.singleAvailableSelection) {
      this.router.navigate(['explore-insights/country', this.singleAvailableSelection.id])
    }
  }
}
